import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
		apiKey: "AIzaSyCBK3tFbf5kqkTzX-s5inUzDTMtyQrna-o",
		authDomain: "skylark-social-fitness-app.firebaseapp.com",
		// databaseURL: "https://PROJECT_ID.firebaseio.com",
		projectId: "skylark-social-fitness-app",
		storageBucket: "skylark-social-fitness-app.appspot.com",
		messagingSenderId: "778341834627",
		appId: "1:778341834627:web:6a4be10b595b302b5060b6",
		measurementId: "G-MWEHDYMHN7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogle = () => auth.signInWithPopup(provider);
