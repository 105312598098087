import {useEffect, useState} from 'react';
import Login from './components/Login';
import { auth, db} from "./services/firebase";
import firebase from "firebase/compat/app";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome, faPlus, faUser, faEdit, faTrash, faSignOutAlt, faMinus, faUserSlash, faUserPlus} from '@fortawesome/free-solid-svg-icons'

import './style.scss';

function App() {
		const [loading, setLoading] = useState(true);
		const [user, setUser] = useState(null);
		const [userProfile, setUserProfile] = useState(null);
		const [appUser, setAppUser] = useState(null);
		const [loggedInClass, setLoggedInClass] = useState("logged-out");
		const [following, setFollowing] = useState([]);
		const [workouts, setWorkouts] = useState([]);
		const [exercises, setExercises] = useState([]);
		const [exerciseSets, setExerciseSets] = useState([]);
		const [workoutModal, setWorkoutModal] = useState("hide");
		const [profileModal, setProfileModal] = useState("hide");
		const [exerciseFields, setExerciseFields] = useState([]);

		const allowedEmails = [
			"benspickett@gmail.com",
			"1undercoversanta1@gmail.com",
			"sally.moya.young@gmail.com"
		];

		useEffect(() => {
				auth.onAuthStateChanged(user => {

					if (user && allowedEmails.includes(user.email)) {
								setUser(user);

								if (user) {
												setLoggedInClass("logged-in");
												getOrCreateUser(user).then((userData) => {
																setAppUser(userData);
																getFollowingUsers(userData).then((followingUsers) => {
																		setFollowing(followingUsers);
																		setLoading(false);
																});
												});
												loadFeed();
								}
					} else {
						 signOut();
							setLoading(false);
					}

				});

		}, [setUser]);

		const signOut = () => {
				auth.signOut();
				setUser(null);
				setFollowing([]);
				setLoggedInClass("logged-out");
		};

		const getOrCreateUser = async (user) => {
				const userRef = await db.collection('users').doc(user.uid);
				const userSnapshot = await userRef.get();

				if (!userSnapshot.exists) {
						await userRef.set({
								email: user.email,
								name: user.displayName,
								photoURL: user.photoURL,
								uid: user.uid,
								following: [],
						});
				}

				const userData = await userRef.get();
				setAppUser(userData.data());
				return	userData.data();
		};

		const getUserById = async (userId) => {
				const userRef = await db.collection('users').doc(userId);
				const userData = await userRef.get();
				return	userData.data();
		};

		const getWorkoutById = async (workoutId) => {
				const workoutRef = await db.collection('workouts').doc(workoutId);
				const workoutData = await workoutRef.get();
				return	workoutData.data();
		};

		const getExercisesByWorkoutId = async (workoutId) => {
				const exercisesRef = await db.collection('workout_exercises').where('workout_id', '==', workoutId).get();
				return exercisesRef.docs.map(doc => doc.data());
		};

		const getFollowingUsers = async (userData) => {
				const following = userData.following;
				const followingUsers = [];

				for (let i = 0; i < following.length; i++) {
						const userRef = await db.collection('users').doc(following[i]);
						const userSnapshot = await userRef.get();
						followingUsers.push(userSnapshot.data());
				}

				setFollowing(followingUsers);
				return followingUsers;
		};

		const followUser = (userId) => {
				const userRef = db.collection('users').doc(appUser.uid);
				userRef.update({
						following: firebase.firestore.FieldValue.arrayUnion(userId),
				});
				setAppUser({...appUser, following: [...appUser.following, userId]});

				db.collection('users').doc(userId).get().then((userSnapshot) => {
						const userData = userSnapshot.data();
						setFollowing([...following, userData]);
				});
		};

		const unfollowUser 	= (userId) => {
				const userRef = db.collection('users').doc(appUser.uid);
				userRef.update({
						following: firebase.firestore.FieldValue.arrayRemove(userId),
				});
				setAppUser({...appUser, following: appUser.following.filter(user => user.uid !== userId)});
				setFollowing(following.filter(user => user.uid !== userId));
		};

		const isFollowing = (userId) => {
				if (userId && following) {
						return following.find(user => user.uid === userId);
				}
				return false;
		};

		const fetchWorkouts = async () => {
				const response = db.collection('workouts').orderBy("date_time", "desc");
				return await response.get().then(snapshot => {
						return snapshot.docs.map(doc => {
								return {
										id: doc.id,
										...doc.data()
								}
						});
				});
		};

		const fetchExercises = async () => {
				const response = db.collection('workout_exercises');
				return await response.get().then(snapshot => {
						return snapshot.docs.map(doc => {
								return {
										id: doc.id,
										...doc.data()
								}
						});
				});
		};

		const fetchExerciseSets = async () => {
				const response = db.collection('exercise_sets').orderBy("set_number", "asc");
				return await response.get().then(snapshot => {
						return snapshot.docs.map(doc => {
								return {
										id: doc.id,
										...doc.data()
								}
						});
				});
		};

		const goHome	= () => {
				hideWorkoutModal();
				hideProfileModal();
		};

		const showWorkoutModal = async (workoutId = null) => {
				hideProfileModal();

				if (workoutId) {
						let workout = await getWorkoutById(workoutId);
						console.log(workout);
						let exercises = await getExercisesByWorkoutId(workoutId);
						console.log(exercises);
				}

				setWorkoutModal("show");
				document.body.classList.add("no-scroll");
		};

		const hideWorkoutModal = () => {
				setWorkoutModal("hide");
				setExerciseFields([]);
				document.body.classList.remove("no-scroll");
		};

		const showProfileModal = async (userToShow, id = null) => {
				hideWorkoutModal();
				if (id) {
						userToShow = await getUserById(userToShow);
				}
				setUserProfile(userToShow);
				setProfileModal("show");
				document.body.classList.add("no-scroll");
		};

		const hideProfileModal = () => {
				setProfileModal("hide");
				setUserProfile(null);
				document.body.classList.remove("no-scroll");
		};

		const scrollToTop = (element = null) => {
				if (element) {
						document.getElementById(element).scrollTo(0, 0);
				} else {
						window.scrollTo(0, 0)
				}
		}

		const saveWorkout = () => {
				const workout_name = document.getElementById("workout_name").value;
				const workout_location = document.getElementById("workout_location").value;
				const exercise_names = document.getElementsByClassName("exercise-name");
				let exercise_sets = null;
				setLoading(true);
				scrollToTop("new-workout-container");

				db.collection("workouts").add({
						date_time: new Date(),
						location: workout_location,
						name: workout_name,
						owner_firstname: user.displayName.split(" ")[0],
						owner_lastname: user.displayName.split(" ")[1],
						owner_id: user.uid,
						owner_image: user.photoURL,
				}).then((workoutResponse) => {

						for (let i = 0; i < exercise_names.length; i++) {
								const data_number = exercise_names[i].getAttribute("data-field");
								db.collection("workout_exercises").add({
										name: exercise_names[i].value,
										workout_id: workoutResponse.id
								}).then((exerciseResponse) => {
										exercise_sets = document.getElementsByClassName("exercise-sets-for-"+data_number);

										for (let k = 0; k < exercise_sets.length; k++) {
												db.collection("exercise_sets").add({
														exercise_id: exerciseResponse.id,   // From exerciseResponse
														number_of_reps: exercise_sets[k].getElementsByClassName("reps")[0].value,
														set_number: exercise_sets[k].getElementsByClassName("set")[0].value,
														weight: exercise_sets[k].getElementsByClassName("weight")[0].value,
												}).then((exerciseSetsResponse) => {
														// wait for 1 second then loadFeed
														setTimeout(()=>{
																hideWorkoutModal();
																loadFeed();
																setExerciseFields([]);
																setLoading(false);
														}, 1000)

												}).catch((error) => {console.error(error)});// End exercise_sets
										}
								}).catch((error) => {console.error(error)}); // End workout_exercises

						}

				}).catch((error) => {console.error(error)}); // End workouts
		};

		const deleteWorkout = (workoutId) => {
				db.collection('workouts').doc(workoutId).delete();

				db.collection('workout_exercises').where('workout_id', '==', workoutId).get().then((workouts) => {
						workouts.forEach((workout) => {
								db.collection('workout_exercises').doc(workout.id).delete();

								db.collection('exercise_sets').where('exercise_id', '==', workout.id).get().then((sets) => {
										sets.forEach((set) => {
												db.collection('exercise_sets').doc(set.id).delete();
										});

								});
						});
				}).then(() => {
						loadFeed();
				});
		};

		const editWorkout = (workoutId) => {
				showWorkoutModal(workoutId);
		};

		const loadFeed = () => {
				fetchWorkouts().then(workouts => {
						setWorkouts(workouts);

						fetchExercises().then(exercises => {
								setExercises(exercises);

								fetchExerciseSets().then(exerciseSets => {
										setExerciseSets(exerciseSets);
								});
						});
				});
		};

		const addNewExerciseField = () => {
				let newFieldNumber = Math.floor(Math.random() * 99999);

				let newField = {
						number: newFieldNumber,
						sets: []
				};

				let temp = exerciseFields.concat(newField);
				setExerciseFields([...temp]);
		};

		const removeNewExerciseField = (fieldNumber) => {
				let temp = exerciseFields.filter(function (field) {
						return field.number !== fieldNumber;
				});
				setExerciseFields([...temp]);
		};

		const addExerciseSetField = (fieldNumber) => {
				let exerciseField = exerciseFields.filter((field) => {
						return field.number === fieldNumber;
				})[0];

				let newFieldNumber = Math.floor(Math.random() * 99999);
				let newSetField = exerciseField.sets.concat(newFieldNumber);

				let updatedField = {
						number: fieldNumber,
						sets: newSetField
				};

				let temp = exerciseFields;
				let index = exerciseFields.findIndex(field => field.number === fieldNumber);

				temp[index] = updatedField;
				setExerciseFields([...temp]);
		};

		const removeExerciseSetField = (parentFieldNumber, fieldNumber) => {
				let exerciseField = exerciseFields.filter((field) => {
						return field.number === parentFieldNumber;
				})[0];

				let updatedSets	= exerciseField.sets.filter(function (field) {
						return field !== fieldNumber;
				});

				let updatedField = {
						number: parentFieldNumber,
						sets: updatedSets
				};

				let temp = exerciseFields;
				let index = exerciseFields.findIndex(field => field.number === parentFieldNumber);

				temp[index] = updatedField;
				setExerciseFields([...temp]);
		};

		const hasRecentWorkout = (userUid) => {
				const allWorkouts = workouts;
				const twentyFourHoursAgo = firebase.firestore.Timestamp.now().seconds - 86400;
				let result = false;
				allWorkouts.forEach((workout) => {
						if (workout.owner_id === userUid) {
								if (workout.date_time.seconds > twentyFourHoursAgo) {
										result = true;
								}
						}
				});
				return result;
		}

		return (
						<div className={`app ${loggedInClass}`}>

								<div className="header">
										<h1 className="site-name">Lifty</h1>
										{user ?
														<div className="user-info">
																<p className="user-name">{user.displayName}</p>
																<p className="user-id">{user.uid}</p>
																<img src={user.photoURL} alt="" className="user-image"/>
														</div>
														:
														""
										}
								</div>

								<div className="body">
										{(user) ?

														<div className="main">

																<h2>The Latest</h2>
																<div className="user-container">
																		<div className="user-info">
																				{(loading) ? <div className="loader"/> : (following.length === 0) ? "Follow people to see them here..." : "" }
																				{following && following.map(user => {
																						return (
																										<div className="following-user" key={user.uid}>
																												<img src={user.photoURL} onClick={() => showProfileModal(user)} alt={user.name} title={user.name}/>
																												{(hasRecentWorkout(user.uid)) ?
																																<span className="recent-workout-tag"/> :
																																""
																												}
																										</div>
																						)
																				})}
																		</div>
																</div>

																<div className="feed-container">

																		<div className="feed-content">

																				{(workouts.length === 0) ? <div className="loader"/> : ""}
																				{workouts && workouts.map(workout => {
																						return (
																										<div key={workout.id} className="workout-container">
																												<span><img src={workout.owner_image} onClick={() => showProfileModal(workout.owner_id, true)} alt={workout.owner_firstname} title={workout.owner_firstname}/></span>
																												<h4>{workout.owner_firstname} {workout.owner_lastname}</h4>

																												{(workout.owner_id === user.uid) ? <p className="actions">
																														{/*<button className="edit-workout-button" onClick={() => editWorkout(workout.id)}><FontAwesomeIcon icon={faEdit} /></button>*/}
																														<button className="delete-workout-button" onClick={() => deleteWorkout(workout.id)}><FontAwesomeIcon icon={faTrash} /></button>
																												</p> : ""}

																												{(workout && !isFollowing(workout.owner_id) && (workout.owner_id !== user.uid)) ? <p className="actions">
																														<button onClick={() => followUser(workout.owner_id)}>Follow</button>
																												</p> : ""}

																												<span className="datetime">{new Date(workout.date_time.seconds * 1000).toDateString()} @ {new Date(workout.date_time.seconds * 1000).toLocaleTimeString()}</span>
																												<div className="workout-details">
																														<div className="workout-name-location">{workout.name} at {workout.location}</div>
																												</div>

																												{(exercises.length === 0) ? <div className="loader"/> : ""}
																												{exercises && exercises.map(exercise => {
																														return (
																																		(exercise.workout_id === workout.id) ?
																																						<div className="exercise" key={exercise.id}>
																																								<div className="workout-exercise-name">{exercise.name}</div>

																																								{(exerciseSets.length === 0) ? <div className="loader"/> : ""}
																																								{exerciseSets && exerciseSets.map(exerciseSet => {
																																										return (
																																														(exerciseSet.exercise_id === exercise.id) ?
																																																		<div className="exercise-set" key={exerciseSet.id}>
																																																				<p>{exerciseSet.number_of_reps} reps @ {exerciseSet.weight}kg</p>
																																																		</div>
																																																		: ""
																																										)
																																								})}
																																						</div> : ""
																														)
																												})}

																										</div>
																						)
																				})
																				}
																		</div>
																</div>

														</div>
														:
														<div className="login-link">
																{(loading) ? <div className="loader"/> : <Login/>}
														</div>
										}
								</div>

								{user ?
										<div className="footer">
														<div className="actions">
																<button className="home" onClick={() => goHome()}><FontAwesomeIcon icon={faHome}/></button>
																<button className="add-workout" onClick={() => showWorkoutModal()}><FontAwesomeIcon icon={faPlus}/></button>
																<button className="show-profile" onClick={() => showProfileModal(user)}><FontAwesomeIcon icon={faUser}/></button>
																<button className="signout" onClick={() => signOut()}><FontAwesomeIcon icon={faSignOutAlt}/></button>
														</div>
										</div>
										:""
								}

								{(user && userProfile) ?
										<div className={`user-profile ${profileModal}`}>
												<div className="inner">
														<div className="user-details">
																<div className="user-image"><img src={userProfile.photoURL} alt="" className="user-image"/></div>
																<div className="user-name"><h3>{(userProfile.name) ? userProfile.name : userProfile.displayName}</h3></div>
														</div>

														<div className="user-workouts">
																<h2>{(userProfile.name) ? "Workouts" : "Your workouts"}</h2>
																{(workouts.length === 0) ? <div className="loader"/> : ""}
																{workouts && workouts.map(workout => {
																		return (
																						(workout.owner_id === userProfile.uid) ?
																						<div key={workout.id} className="workout-container">
																								<span><img src={workout.owner_image} alt={workout.owner_firstname} title={workout.owner_firstname}/></span>
																								<h4>{workout.owner_firstname} {workout.owner_lastname}</h4>

																								{(workout.owner_id === user.uid) &&
																								<p className="actions">
																										{/*<button className="edit-workout-button" onClick={() => editWorkout(workout.id)}><FontAwesomeIcon icon={faEdit} /></button>*/}
																										<button className="delete-workout-button" onClick={() => deleteWorkout(workout.id)}><FontAwesomeIcon icon={faTrash} /></button>
																								</p>
																								}

																								<span className="datetime">{new Date(workout.date_time.seconds * 1000).toDateString()} @ {new Date(workout.date_time.seconds * 1000).toLocaleTimeString()}</span>
																								<div className="workout-details">
																										<div className="workout-name-location">{workout.name} at {workout.location}</div>
																								</div>

																								{(exercises.length === 0) ? <div className="loader"/> : ""}
																								{exercises && exercises.map(exercise => {
																										return (
																														(exercise.workout_id === workout.id) ?
																																		<div className="exercise" key={exercise.id}>
																																				<div className="workout-exercise-name">{exercise.name}</div>

																																				{(exerciseSets.length === 0) ? <div className="loader"/> : ""}
																																				{exerciseSets && exerciseSets.map(exerciseSet => {
																																						return (
																																										(exerciseSet.exercise_id === exercise.id) ?
																																														<div className="exercise-set" key={exerciseSet.id}>
																																																<p>{exerciseSet.number_of_reps} reps @ {exerciseSet.weight}kg</p>
																																														</div>
																																														: ""
																																						)
																																				})}
																																		</div> : ""
																										)
																								})}

																						</div> :""
																		)
																})
																}
														</div>


														<div className="profile-actions">
																{(user.uid === userProfile.uid) ?
																				<button className="signout" onClick={() => signOut()}><FontAwesomeIcon icon={faSignOutAlt}/> Logout</button>
																				:
																				(isFollowing(userProfile.uid)) ?
																								<button className="unfollow" onClick={() => unfollowUser(userProfile.uid)}><FontAwesomeIcon icon={faUserSlash}/> Unfollow</button>
																								:
																								<button className="follow" onClick={() => followUser(userProfile.uid)}><FontAwesomeIcon icon={faUserPlus}/> Follow</button>
																}
														</div>

												</div>
										</div>
										:""
								}

								{user ?
										<div className={`new-workout-container ${workoutModal}`} id="new-workout-container">
										<div className="inner">
												<h2>New Workout</h2>

												<div className={`form ${(loading) ? 'loading': ''}`}>
														{(loading) && <div className="loader"/>}
														<input type="text" id="workout_name" placeholder="Workout name"/>
														<input type="text" id="workout_location" placeholder="Location"/>

														{exerciseFields && exerciseFields.map(field => {
																return (
																				<div key={field.number}>
																						<div className="exercise-field" id={`exercise-field-` + field.number}>
																								<input type="text" placeholder="Exercise name" className="exercise-name" data-field={field.number}/>

																								<button className="remove-exercise-button" onClick={() => removeNewExerciseField(field.number)}><FontAwesomeIcon icon={faMinus}/></button>

																								{field && field.sets.map((setField, key) => {
																										return (
																														<div key={setField} className={`exercise-sets exercise-sets-for-` + field.number}>
																																<p>
																																		<input type="text" className="set" readOnly={true} value={key + 1}/>
																																		<input type="text" className="weight" placeholder="weight"/>
																																		<input type="text" className="reps" placeholder="reps"/>
																																		<button onClick={() => removeExerciseSetField(field.number, setField)}><FontAwesomeIcon icon={faMinus}/></button>
																																</p>
																														</div>
																										)
																								})}

																								<button className="add-set-button" onClick={() => addExerciseSetField(field.number)}><FontAwesomeIcon icon={faPlus}/> Add set</button>

																						</div>
																				</div>

																)
														})}

														<div className="add-exercise-container">
																<button className="add-exercise-button" onClick={() => {addNewExerciseField()}}><FontAwesomeIcon icon={faPlus}/></button>
																<span className="add-exercise-text">Add an exercise</span>
														</div>

														<p>
																<button className="save-button" onClick={() => saveWorkout()}>Save</button>
																<button className="cancel-button" onClick={() => hideWorkoutModal()}>Cancel</button>
														</p>

												</div>


										</div>
								</div>
										:""
								}

						</div>
		);
}

export default App;
