import { signInWithGoogle } from '../services/firebase';

import '../style.scss';

const Login = () => {
	return (
		<div>
			<a href="#" className="button login" onClick={signInWithGoogle}>
				<img src="google-logo.png" className="google-logo" alt="Sign in with Google" />
			</a>
				<span>Log in</span>
		</div>
	)
};

export default Login;